<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="barrels"
              :show-labels="true"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              @search="getData"
              @change="filterData"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <barrels-table ref="barrels" @loaded="onBarrelsLoad" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import BarrelsTable from "@/views/PosAndSamples/BarrelsTable";

export default {
  name: "Accounts",
  components: {
    FilteringPanel,
    BarrelsTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            title: "Barrel status",
            name: "barrel_status",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "1",
                label: "Full"
              },
              {
                id: "2",
                label: "Top off"
              },
              {
                id: "3",
                label: "Reserved"
              }
            ],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Barrel status",
            multiple: true
          },
          {
            type: "select",
            title: "Selection status",
            name: "barrel_selection_status",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "-1",
                label: "Unavailable"
              },
              {
                id: "0",
                label: "Available"
              },
              {
                id: "1",
                label: "Selected"
              },
              {
                id: "2",
                label: "Sold"
              }
            ],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Selection status",
            multiple: true
          },
          {
            type: "select",
            title: "Bottling status",
            name: "barrel_bottling_status",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "0",
                label: "Not bottled"
              },
              {
                id: "1",
                label: "Bottled"
              }
            ],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Bottling status",
            multiple: true
          },
          {
            type: "select",
            title: "Requested By",
            name: "requested_by",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Responsible user name",
            multiple: true
          },
          {
            type: "select",
            title: "Selections count",
            name: "barrel_selection_count",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "0",
                label: "0"
              },
              {
                id: "1",
                label: "1"
              },
              {
                id: "2",
                label: "2"
              },
              {
                id: "3",
                label: "3"
              },
              {
                id: "4",
                label: "4"
              },
              {
                id: "5",
                label: "5"
              },
              {
                id: "6",
                label: "6"
              },
              {
                id: "7",
                label: "7"
              },
              {
                id: "8",
                label: "8"
              },
              {
                id: "9",
                label: "9"
              },
              {
                id: "10",
                label: "10"
              }
            ],
            selected: {},
            dataType: "number",
            tooltip: "Selection count",
            multiple: true
          },
          {
            type: "select",
            title: "Amount remaining",
            name: "barrel_amount_remaining",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "0",
                label: "0",
                min: 0,
                max: 0
              },
              {
                id: "1",
                label: " 0.1 - 100",
                min: 0.1,
                max: 100
              },
              {
                id: "2",
                label: "100.01 - 200",
                min: 100.01,
                max: 200
              },
              {
                id: "3",
                label: "200.01 - 300",
                min: 200.01,
                max: 300
              }
            ],
            selected: {},
            ///
            dataType: "number",
            tooltip: "Amount remaining",
            multiple: true
          },

          {
            type: "daterange",
            defaultRange: "This year",
            title: "Reservation Date",
            name: "barrel_reservation_period",
            dataType: "datetime",
            tooltip: "Reservation period",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "This year",
            title: "Selection Date",
            name: "barrel_selection_period",
            dataType: "datetime",
            tooltip: "Selection period",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "Last 10 years",
            title: "Barrel Date",
            name: "barrel_date",
            dataType: "datetime",
            tooltip: "Barrel date",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "Last 10 years",
            title: "Modified Date",
            name: "barrel_modified_date",
            dataType: "datetime",
            tooltip: "Modified date",
            allowEmpty: true
          },
          {
            type: "input",
            title: "Barrel #",
            name: "barrel_number",
            dataType: "string",
            tooltip: "Barrel number to search"
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "requested_by"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      await Promise.all([users()]);
    },

    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },
    onBarrelsLoad() {},
    filterData(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        requested_by: f.requested_by ? f.requested_by.map(i => i.id) : [],
        barrel_status: f.barrel_status ? f.barrel_status.map(i => i.label) : [],
        barrel_selection_status: f.barrel_selection_status
          ? f.barrel_selection_status.map(i => i.label)
          : [],
        barrel_selection_count: f.barrel_selection_count
          ? f.barrel_selection_count.map(i => i.label)
          : [],
        barrel_bottling_status: f.barrel_bottling_status
          ? f.barrel_bottling_status.map(i => i.label)
          : [],
        barrel_reservation_period: f.barrel_reservation_period,
        barrel_selection_period: f.barrel_selection_period,
        barrel_date: f.barrel_date,
        barrel_modified_date: f.barrel_modified_date,
        barrel_number: f.barrel_number
      };

      if (f.barrel_amount_remaining && f.barrel_amount_remaining.length > 0) {
        let minAmountRemaining = f.barrel_amount_remaining.reduce(
          (min, p) => (p.min < min ? p.min : min),
          f.barrel_amount_remaining[0].min
        );

        let maxAmountRemaining = f.barrel_amount_remaining.reduce(
          (max, p) => (p.max > max ? p.max : max),
          f.barrel_amount_remaining[0].max
        );

        payload.barrel_amount_remaining = {
          minAmountRemaining: minAmountRemaining,
          maxAmountRemaining: maxAmountRemaining
        };
      }

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["barrels"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
